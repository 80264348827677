import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./redux/reducer/auth.reducer";
import { toastMiddleware } from "./redux/middleware/toast.middleware";
import { tokenMiddleware } from "./redux/middleware/token.middleware";
import { themeReducer } from "./redux/reducer/theme.reducer";
import { dashboardDataReducer } from "./redux/reducer/dashboard_data.reducer";
import { mainDataReducer } from "./redux/reducer/main_data.reducer";

const store = configureStore({
    reducer: { authReducer, themeReducer, dashboardDataReducer, mainDataReducer },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(toastMiddleware, tokenMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>

export default store;