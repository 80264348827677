

export const tokenMiddleware = (store: any) => {
    return function (next: any) {
        return function (action: any) {
            switch (action.type) {
                case "auth/loginUser/fulfilled":
                case "auth/signUpUser/fulfilled":
                case "auth/verifyToken/fulfilled":
                    console.log("token set", action.payload.data.token);
                    localStorage.setItem("token", action.payload.data.token);
                    break;
                case "auth/logoutUser/fulfilled":
                    console.log("token remove");
                    localStorage.removeItem("token");
                    break;
                default:
                    if(action.payload){
                        console.log("payload",action.payload);
                    }
            }
            next(action);
        };
    };
};
