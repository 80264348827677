import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initThemeState: number = 0;

const themeSlice = createSlice({
    name: "theme",
    initialState: initThemeState,
    reducers: {
        darker: (state) => state + 1,
        lighter: (state) => state - 1,
        start: () => 0,
        setFromTime: (state, action: PayloadAction<number>) => {
            state = action.payload;
        }
    }
})

export const themeReducer = themeSlice.reducer;
export const themeAction = themeSlice.actions;
export const themeSelector = (state: RootState) => state.themeReducer;