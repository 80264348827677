// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_background-0__Yq58z {
  background-color: rgb(255, 255, 255);
}

.App_background-1__lzvrl {
  background-color: rgb(255, 255, 100);
}

.App_background-2__pdeWn {
  background-color: rgb(255, 100, 100);
}

.App_background-3__qNl4E {
  background-color: rgb(100, 100, 100);
}

.App_background-4__UH0wG {
  background-color: rgb(0, 0, 0);
}

.App_apps__4xCSy {
  height: 100vh;
  width: 100vw;
}`, "",{"version":3,"sources":["webpack://./src/App.module.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;;AACA;EACI,oCAAA;AAEJ;;AAAA;EACI,oCAAA;AAGJ;;AADA;EACI,oCAAA;AAIJ;;AAFA;EACI,8BAAA;AAKJ;;AAFA;EACI,aAAA;EACA,YAAA;AAKJ","sourcesContent":[".background-0{\n    background-color: rgb(255, 255, 255);\n}\n.background-1{\n    background-color: rgb(255, 255, 100);\n}\n.background-2{\n    background-color: rgb(255, 100, 100);\n}\n.background-3{\n    background-color: rgb(100, 100, 100);\n}\n.background-4{\n    background-color: rgb(0, 0, 0);\n}\n\n.apps{\n    height: 100vh;\n    width: 100vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background-0": `App_background-0__Yq58z`,
	"background-1": `App_background-1__lzvrl`,
	"background-2": `App_background-2__pdeWn`,
	"background-3": `App_background-3__qNl4E`,
	"background-4": `App_background-4__UH0wG`,
	"apps": `App_apps__4xCSy`
};
export default ___CSS_LOADER_EXPORT___;
