import { toast } from "react-toastify";

export const toastMiddleware = (store: any) => {
  return function (next: any) {
    return function (action: any) {
      switch (action.type) {
        case "auth/loginUser/fulfilled":
          toast.info("Logged in successfully");
          break;
        case "auth/signUpUser/fulfilled":
          toast.info("SignUp successfully");
          break;
        case "auth/logoutUser/fulfilled":
          toast.info("Logged out successfully");
          break;
        default:
          console.log("loggin",action);
          
          if (action.error?.message) {
            toast.error(action.error.message);
          }
      }
      next(action);
    };
  };
};
