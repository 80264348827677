import React, { useEffect } from 'react';
import Template1 from './componenets/template1/template1';
import Episode from './componenets/expisode/episode';

import './App.scss';
import styles from './App.module.scss';

function App() {
	useEffect(() => {
		// dispatch(loginUser({ email: "test123@test.com", password: "testing123" }));
	}, []);

	return (
		<div className={styles.apps}>
			{/* <Template1 index={1}></Template1> */}
			<Episode index={0} />
		</div>
	);
}

export default App;
