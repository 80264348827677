const loream = "Delirious, Baghdadi begins to roam the streets of the city, vary of becoming a ghost himself - an idle stroller in search of a revelation. ‘No matter what happens’, he mutters to himself, ‘I must ensure that this city does not reduce me to ashes; it does not render me a curiosity’. Out of work and by now an official mendicant, Baghdadi realises that the only way in which he can preserve his material identity is by ‘being with other people, in their midst, sharing a warm place with them’. He scours his pockets for leftover change and locates a half-rupee coin - enough for a single bus ticket. ‘But where can I go?’, he wonders, and then, clarity: ‘...when I stared closely at this city made of mortar, I became a ghost; if I stare at an abstraction, perhaps my body will be restored to me.’ He stares up at the curb of the road and notices the bus to Mahim conjure itself at the corner. Where else can the lost of the city go to become people again, but to its cinemas?";
const story = `
Roshan has never been trustworthy - he wasn’t yesterday, he isn’t
today. His body reeks of the ‘eternal stench’ (in the unions of film
workers that dot the city’s landscape, the phrase serves as the
euphemism for the odd smell one can detect on the bodies of those
technicians that work a shift near the Goregaon swamps - this in
reference to the thousands of bodies of dissenting workers buried
in them). And yet, it is not home that Rafique seeks tonight, but
refuge. As he crosses the disintegrating, rusted iron bridge at night,
he can locate the fixture of blue tarpaulin by the dirty stream.`;

export interface Template1DataInterface {
    type: string,
    title: string,
    isShortForm: boolean | undefined,
    subTitle: string,
    thumbnail: string,
    background: string,
    logo: string,
    twitter: string,
    instagram: string,
    facebook: string,
}

export interface EpisodeDataInterface {
    type: string,
    theme: string,
    title: string,
    episodeNumber: string,
    preparedBy: string,
    image1: string,
    image2: string,
    story: string,
    background: string
}

const data = [
    {
        type: "episode",
        theme: "yellow",
        title: "Rafique raat\nki baahon main",
        episodeNumber: "Episode One",
        preparedBy: "Ketan Dua",
        story: story,
        image1: "./images/marlka.jpeg",
        image2: "./images/Local, 3.jpg",
        background: "./images/black_back.jpeg",
    } as EpisodeDataInterface,
    {
        type: "template1",
        title: "prologue",
        isShortForm: false,
        subTitle: loream,
        thumbnail: "./images/imagepr1.png",
        background: "./images/background.png",
        logo:"./images/logo.png",
        facebook:"./images/facebook.png",
        twitter:"./images/twitter.png",
        instagram:"./images/instagram.png"

    } as Template1DataInterface,
]
export default data;
