import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import initData from "../constants/main_objects";

const mainDataSlice = createSlice({
    name: "dashboard_data",
    initialState: initData,
    reducers: {}
})

export const mainDataReducer = mainDataSlice.reducer;
export const mainDataAction = mainDataSlice.actions;
export const mainDataSelector = (state: RootState) => state.mainDataReducer;