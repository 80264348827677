import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styles from './episode.module.scss';
import { useSelector } from 'react-redux';
import { dashboardDataSelector } from '../../redux/reducer/dashboard_data.reducer';
import { EpisodeDataInterface } from '../../redux/constants/dashboard_objects';
import { mainDataSelector } from '../../redux/reducer/main_data.reducer';

interface EpisodeType {
	index: number;
}

interface customCSSProperties extends CSSProperties {
	'--themeColor': string | undefined;
}

const Episode = ({ index }: EpisodeType) => {
	const data = useSelector(dashboardDataSelector)[index] as EpisodeDataInterface;
	const customStyles: customCSSProperties = { '--themeColor': data.theme };

	const [showStory, setShowStory] = useState(false);
	const imageRef = useRef<HTMLImageElement>(null);
	const [height, setHeight] = useState(0);

	const handleStory = () => {
		setShowStory(!showStory);
	};

	useEffect(() => {
		if (!imageRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			console.log('vinayak changed size', imageRef?.current?.offsetHeight ?? 0);
			setHeight(imageRef?.current?.offsetHeight ?? 0);
		});
		resizeObserver.observe(imageRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<>
			<img className={styles.background} src={data.background} alt="background"></img>
			<div className={styles.head} style={customStyles}>
				<div className={styles.component_1}>
					<img className={styles.logo} src="./images/Logo_Yellow.png" alt="logo" />
					<Menu />
				</div>
				<div className={styles.component_gap}></div>
				<div className={styles.component_2}>
					<EpisodeComponent index={index} showStory={showStory} imageRef={imageRef} />
				</div>
				<div className={styles.component_gap}></div>
				<div className={styles.component_3}>
					<EpisodeComponent2 height={height} index={index} onStoryClick={handleStory} />
				</div>
				<div className={styles.component_gap}></div>
			</div>
		</>
	);
};

const Menu = () => {
	return (
		<div className={styles.menu}>
			<p className={styles.item}>Fiction</p>
			<div className={styles.gap}></div>
			<p className={styles.item}>About</p>
			<div className={styles.gap}></div>
			<p className={styles.item}>User</p>
			<div className={styles.gap}></div>
			<p className={styles.item}>Archive</p>
		</div>
	);
};

const EpisodeComponent = ({
	index,
	showStory,
	imageRef
}: {
	index: number;
	showStory: boolean;
	imageRef: React.RefObject<HTMLImageElement>;
}) => {
	const data = useSelector(dashboardDataSelector)[index] as EpisodeDataInterface;
	const elementRef = useRef<HTMLInputElement>(null);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		if (!imageRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			console.log('vinayak changed size', imageRef?.current?.offsetHeight ?? 0);
			setWidth(elementRef?.current?.offsetWidth ?? 0);
		});
		resizeObserver.observe(imageRef.current);
		return () => resizeObserver.disconnect();
	}, []);
	return (
		<>
			<div className={styles.image_height}>
				<img
					style={{ width: width }}
					className={styles.image1}
					src={data.image1}
					alt={data.image1}
					ref={imageRef}
				/>
			</div>
			<div className={styles.flexbox} ref={elementRef}>
				<p className={styles.themeColor}>{data.episodeNumber} </p>
				<Story index={index} showStory={showStory} width={width} />
			</div>
		</>
	);
};

const Story = ({ index, showStory, width }: { index: number; showStory: boolean; width: number }) => {
	const data = useSelector(dashboardDataSelector)[index] as EpisodeDataInterface;
	const titles = data.title.split('\n');
	return (
		<>
			{showStory && (
				<p className={styles.story} style={{ width }}>
					{data.story}
				</p>
			)}
			<div style={{visibility: showStory ? 'hidden' : 'visible'}} className={styles.flex_grow2}></div>
			{titles.map((title, i) => (
				<h1 style={{visibility: showStory ? 'hidden' : 'visible'}} className={styles.title} key={i}>
					{title}
				</h1>
			))}
			<div style={{visibility: showStory ? 'hidden' : 'visible'}} className={styles.flex_grow3}></div>
			<p style={{visibility: showStory ? 'hidden' : 'visible'}}>
				<span className={styles.themeColor}>prepared By</span>
				&nbsp;
				<span className={styles.prepared_by}>{data.preparedBy}</span>
			</p>
		</>
	);
};

const EpisodeComponent2 = ({
	index,
	height,
	onStoryClick
}: {
	index: number;
	height: number;
	onStoryClick: () => void;
}) => {
	const data = useSelector(dashboardDataSelector)[index] as EpisodeDataInterface;
	const mainData = useSelector(mainDataSelector);
	const imageRef = useRef<HTMLImageElement>(null);

	const [width, setWidth] = useState(0);

	useEffect(() => {
		if (!imageRef.current) return;
		const resizeObserver = new ResizeObserver(() => {
			setWidth(imageRef?.current?.offsetWidth ?? 0);
		});
		resizeObserver.observe(imageRef.current);
		return () => resizeObserver.disconnect();
	}, []);

	return (
		<>
			<div className={styles.com3_top} style={{ height: height }}>
				<img className={styles.mark} src="./images/mark_1.png" alt="mark"></img>
				<div style={{ width: width, display: 'flex', justifyContent: 'flex-end' }}>
					<div className={styles.social}>
						<img
							src="./images/twitter.png"
							alt="twitter_logo"
							onClick={() => window.open(mainData.twitter)}
						/>
						<div className={styles.gap} />
						<img
							src="./images/facebook.png"
							alt="facebook_logo"
							onClick={() => window.open(mainData.facebook)}
						/>
						<div className={styles.gap} />
						<img
							src="./images/instagram.png"
							alt="instagram_logo"
							onClick={() => window.open(mainData.instagram)}
						/>
					</div>
				</div>
			</div>
			<div className={styles.gap}></div>
			<img className={styles.image} src={data.image2} alt={data.image2} ref={imageRef} />
			<div className={styles.flexbox}>
				<p className={styles.item}>Play</p>
				<p className={styles.item} onClick={onStoryClick}>
					Story
				</p>
			</div>
		</>
	);
};

export default Episode;
