import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import initData from "../constants/dashboard_objects";

const dashboardDataSlice = createSlice({
    name: "dashboard_data",
    initialState: initData,
    reducers: {}
})

export const dashboardDataReducer = dashboardDataSlice.reducer;
export const dashboardDataAction = dashboardDataSlice.actions;
export const dashboardDataSelector = (state: RootState) => state.dashboardDataReducer;